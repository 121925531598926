import { Component, AfterViewChecked, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CdkConnectedOverlay, ConnectedPosition } from '@angular/cdk/overlay';
import { Subject, Observable, BehaviorSubject, of, combineLatest, merge } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, switchMap, startWith, map } from 'rxjs/operators';

import { mapSuccessData, successData, tapSuccess, truthy } from 'src/app/utils/rxjs-operators';

import { ApiResult } from 'src/app/services/api.service';

@Component({
  selector: 'app-chip-typeahead',
  templateUrl: './pr-chip-typeahead.component.html',
  styleUrls: ['./pr-chip-typeahead.component.scss']
})
export class ChipTypeaheadComponent<T extends Record<string, any>> implements AfterViewChecked, OnDestroy {
  private destroy$ = new Subject<void>();

  @Input() items: T[] = [];
  @Input() api?: (x: string) => Observable<ApiResult<T[]>>;
  @Input() createNewApi?: (x: T) => Observable<ApiResult<T>>;
  @Input() valueKey: keyof T = 'value';
  @Input() textKey: keyof T = 'text';
  @Input() hintKey?: keyof T;
  @Input() addText?: string;
  @Output() itemsChange = new EventEmitter<T[]>();
  @Input() Multiselect = true;

  searchValue$ = new BehaviorSubject<string>('');

  createNew$ = new Subject<T>();
  createNewResult$ = this.createNew$.pipe(
    switchMap(item => this.createNewApi?.(item) || of(undefined)),
    truthy(),
    tapSuccess(item => this.items.push(item)),
    takeUntil(this.destroy$)
  );

  refreshItems$ = new Subject<void>();
  items$ = combineLatest([
    merge(
      this.searchValue$.pipe(
        map(searchValue => searchValue?.trim() || ''),
        debounceTime(2000),
        distinctUntilChanged(),
        switchMap(searchValue => merge(of(searchValue), this.refreshItems$.pipe(map(() => searchValue))))
      )
    ),
    this.createNewResult$.pipe(successData(), startWith(undefined), distinctUntilChanged())
  ]).pipe(
    switchMap(
      ([searchValue, newItem]) =>
        this.api?.(searchValue).pipe(
          mapSuccessData(data => {
            if (newItem) {
              const includeNewItem = !data.find(x => x[this.textKey].toLowerCase() === newItem[this.textKey].toLowerCase());
              if (includeNewItem) data.push(newItem);
            }

            return data;
          })
        ) || of(undefined)
    ),
    truthy(),
    takeUntil(this.destroy$)
  );

  overlayPositions: ConnectedPosition[] = [
    { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
    { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' }
  ];

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay?: CdkConnectedOverlay;
  openOverlay?: HTMLElement;

  constructor() {}

  ngAfterViewChecked() {
    this.cdkConnectedOverlay?.overlayRef?.updatePosition();
  }

  add(item: T): void {
    if (item[this.valueKey] === '0') {
      this.createNew$.next(item);
    } else {
      if (this.Multiselect) this.items.push(item);
      else this.items = [item];
    }

    this.refreshItems$.next();
  }

  remove(index: number) {
    if (index >= 0) {
      this.items.splice(index, 1);

      this.emit();
    }
  }

  close() {
    this.openOverlay = undefined;
    this.searchValue$.next('');
    this.emit();
  }

  emit() {
    this.itemsChange.emit(this.items);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
