<form [formGroup]="addTaskForm">
  <div class="d-flex flex-row" mat-dialog-title>
    <div class="w-50 d-flex mt-2">
      <label class="text-primary"> {{ editMode ? 'Edit' : 'Add' }} Task </label>
    </div>
    <div class="w-50 d-flex flex-row justify-content-end mt-2">
      <button mat-dialog-close mat-mini-fab color="primary" class="closeBtn">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>

  <div class="mt-1" mat-dialog-content>
    <div class="d-flex flex-row">
      <div class="flex-column" style="flex-basis: 60%">
        <div class="d-flex flex-row mt-2">
          <div class="flex-column flex-shrink-1" style="flex-basis: 15%">
            <label class="p-1 body-md"> Title: </label>
          </div>
          <div class="flex-column flex-shrink-1" style="flex-basis: 85%">
            <input #input matInput formControlName="title" style="height: 27px" class="mat-background-gray-100 border w-100" />
          </div>
        </div>
        <div class="d-flex flex-row mt-3">
          <div class="flex-column flex-shrink-1" style="flex-basis: 15%">
            <label class="p-1 body-md"> Note: </label>
          </div>
        </div>

        <div class="d-flex flex-row mt-3">
          <div class="flex-column flex-shrink-1" style="flex-basis: 100%">
            <textarea
              matInput
              formControlName="task"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              class="mat-background-gray-100 border w-100"
              cdkAutosizeMinRows="10"
              cdkAutosizeMaxRows="20"></textarea>
          </div>
        </div>

        <div class="d-flex flex-row mt-3">
          <div class="flex-column" style="flex-basis: 50%">
            <div class="d-flex flex-row">
              <label class="pt-3 body-md"> Assign to: </label>
            </div>
          </div>
          <div class="flex-column ms-3" style="flex-basis: 50%">
            <div class="d-flex flex-row">
              <label class="pt-3 body-md"> Due Date: </label>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row mt-3">
          <div class="flex-column" style="flex-basis: 50%">
            <app-input-select
              label=""
              iconPrefix="person"
              iconSuffix="search"
              [api]="searchRecruiter"
              [(text)]="assignToText"
              [value]="addTaskForm.value.assignedToId"
              (valueChange)="select('assignedToId', $event)">
            </app-input-select>
          </div>
          <div class="flex-column ms-3" style="flex-basis: 50%">
            <mat-form-field>
              <input matInput [matDatepicker]="picker1" formControlName="createdDate" />
              <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="flex-column ms-3" style="flex-basis: 40%">
        <div class="d-flex flex-row mt-2">
          <div class="w-100">
            <label class="p-1 w-100 body-md mat-background-gray-300"> Candidate: </label>
          </div>
        </div>

        <div class="d-flex flex-row mt-3">
          <!-- <app-chip-typeahead class="w-100" [api]="controlTypes.candidates.xref( )"
            [createNewApi]="controlTypes.candidates.createNewApi" [addText]="controlTypes.candidates.name"
            [valueKey]="controlTypes.candidates.valueKey" [textKey]="controlTypes.candidates.textKey"
            [hintKey]="controlTypes.candidates.hintKey" [(items)]="candidates" [Multiselect]="false">
          </app-chip-typeahead> -->

          <app-input-select
            class="w-100"
            label=""
            iconPrefix="person"
            iconSuffix="search"
            [api]="searchCandidate"
            [(text)]="candidateText"
            [value]="addTaskForm.value.profileRef"
            (valueChange)="select('profileRef', $event)">
          </app-input-select>
        </div>
        <div class="d-flex flex-row mt-3">
          <div class="w-100">
            <label class="p-1 w-100 body-md mat-background-gray-300"> Company: </label>
          </div>
        </div>

        <div class="d-flex flex-row mt-3">
          <!-- <app-chip-typeahead class="w-100" [api]="controlTypes.companies.xref( )"
            [createNewApi]="controlTypes.companies.createNewApi" [addText]="controlTypes.companies.name"
            [valueKey]="controlTypes.companies.valueKey" [textKey]="controlTypes.companies.textKey"
            [hintKey]="controlTypes.companies.hintKey" [(items)]="companies" [Multiselect]="false">
          </app-chip-typeahead> -->

          <app-input-select
            class="w-100"
            label=""
            iconPrefix="business"
            iconSuffix="search"
            [api]="searchCompany"
            [(text)]="companyText"
            [value]="addTaskForm.value.companyId"
            (valueChange)="select('companyId', $event)">
          </app-input-select>
        </div>

        <div class="d-flex flex-row mt-3">
          <div class="w-100">
            <label class="p-1 w-100 body-md mat-background-gray-300"> Job: </label>
          </div>
        </div>

        <div class="d-flex flex-row mt-3">
          <app-chip-typeahead
            class="w-100"
            [api]="controlTypes.jobs.xref()"
            [createNewApi]="controlTypes.jobs.createNewApi"
            [addText]="controlTypes.jobs.name"
            [valueKey]="controlTypes.jobs.valueKey"
            [textKey]="controlTypes.jobs.textKey"
            [hintKey]="controlTypes.jobs.hintKey"
            [(items)]="jobs">
          </app-chip-typeahead>
        </div>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="d-flex flex-row" mat-dialog-actions>
    <div class="w-50">
      <mat-checkbox formControlName="isComplete">
        <label class="p-1 body-md"> Complete </label>
      </mat-checkbox>

      <mat-checkbox formControlName="isConfidential">
        <label class="p-1 body-md text-nowrap"> Mark confidential </label>
      </mat-checkbox>
    </div>
    <div class="w-50 d-flex justify-content-end">
      <button mat-stroked-button color="primary" color="primary" (click)="close()">Close</button>
      <button mat-raised-button color="primary" [disabled]="!addTaskForm.valid" (click)="submit()">Save Task</button>
    </div>
  </div>
</form>
