<!--TODO: convert to Bootstrap-->
<mat-chip-listbox>
  <!--the chip itself-->
  <mat-chip class="bg-white mat-border-primary border text-truncate" matTooltipShowDelay="500" [removable]="true" (removed)="remove(i)" *ngFor="let item of items; let i = index">
    <span class="mat-color-primary">{{ item[textKey] }}</span>

    <mat-icon matChipRemove class="mat-color-primary opacity-100">cancel</mat-icon>
  </mat-chip>

  <div class="w-100"></div>
  <!--the add button that triggers the overlay-->
  <div #trigger class="d-flex align-items-center">
    <button mat-icon-button color="primary" (click)="openOverlay = trigger; $event.preventDefault()" *ngIf="!addText">
      <mat-icon class="md-icon-16 me-2">add_circle</mat-icon>
    </button>

    <button mat-button color="primary" (click)="openOverlay = trigger; $event.preventDefault()" *ngIf="addText">
      <div class="d-flex align-items-center">
        <mat-icon class="md-icon-16 me-2">add_circle</mat-icon>
        <span class="body-md lh-0" style="height: 16px">Add {{ addText }}</span>
      </div>
    </button>
  </div>
</mat-chip-listbox>

<!--the overlay containing the search field and search results-->
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayPositions]="overlayPositions"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOpen]="openOverlay === trigger"
  (backdropClick)="close()">
  <ng-container *ngIf="{ value: items$ | async } as items">
    <div class="overlay-panel mh-300px" fxLayout="column">
      <div class="p-2">
        <app-input-text #input placeholder="Search" iconPrefix="search" [iconSuffix]="items.value && items.value.finished === false ? 'loading' : ''" (valueChange)="searchValue$.next($event)">
        </app-input-text>
      </div>

      <ng-container *ngIf="items.value && items.value.success">
        <div class="scroll-y border-top" fxFlex="grow">
          <mat-option class="flex-row" *ngFor="let item of items.value.data" [value]="item" (click)="add(item); input.value = ''" [disabled]="item[valueKey] === '0'">
            <span> {{ item[textKey] }}</span>

            <ng-container *ngIf="item.count !== null && item.count !== undefined">
              <div class="spacer" style="min-width: 20px"></div>

              <span class="mat-small">
                {{ item.count | number : '1.0' : 'en-US' }}
              </span>
            </ng-container>
          </mat-option>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
