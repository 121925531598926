import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, of, Subject, timer } from 'rxjs';
import { map, takeUntil, tap, catchError, filter, take, defaultIfEmpty, switchMap, startWith } from 'rxjs/operators';
import { JwtHelperService } from 'angular-jwt-updated';

import { truthy } from 'src/app/utils/rxjs-operators';

import { CacheService } from './cache.service';
import { ContextService } from './context.service';
import { HttpService } from './http.service';
import { ProgressService } from './progress.service';
import { SocketService } from './socket.service';

import { Role, Seat, UserInfo } from './auth.types';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private destroy$ = new Subject<void>();

  private jwtService = new JwtHelperService();

  chromeToken$ = new BehaviorSubject<string | null | undefined>(undefined); // undefined = no chrome app, null = no auth token

  private __userInfo$ = new BehaviorSubject<UserInfo | null | undefined>(undefined); // undefined = no chrome app, null = no auth token
  userInfo$ = this.__userInfo$.pipe(
    filter(<T>(value: T | null | undefined): value is T | null => value !== undefined)
  );

  constructor(
    private cache: CacheService,
    private context: ContextService,
    private http: HttpService,
    private progress: ProgressService,
    private socket: SocketService,

    private activatedRoute: ActivatedRoute,
    private router: Router,
    private zone: NgZone
  ) {
    this.socket.get<void>('refresh').pipe(
      !this.isInIframe ? startWith(null) : tap(),
      switchMap(() => this.loginWithToken()),
      takeUntil(this.destroy$)
    ).subscribe();

    this.socket.get<{ before: number }>('logout').pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      if (this.userInfo && this.userInfo.issueDate < data.before) {
        this.logout(false);
      }
    });


    window.addEventListener('message', (event: any) => {
    
      if (event.origin === 'https://client.prorank.ai') {
        this.setToken(event.data).pipe(
          switchMap(token => {
            try {
              const userInfo = token ? this.jwtService.decodeToken<UserInfo>(token) : undefined;

              if ((userInfo?.issueDate || 0) < new Date().getTime() - 30 * 60 * 1000) {
                return this.loginWithToken().pipe(map(() => token));
              }
            }
            catch (ex) {
              if (token) {
                return this.loginWithToken().pipe(map(() => token));
              }
            }

            return of(token);
          }),
          takeUntil(this.destroy$)
        ).subscribe(token => {
     

          this.chromeToken$.next(token || null);
         
          if (this.isAuthenticated) return;
          if (this.isInIframe) return;
          if (this.router.url.startsWith('/email')) return;

          const returnUrl = this.activatedRoute.snapshot.queryParams.returnUrl;

          if (returnUrl) {
            void this.router.navigateByUrl(returnUrl);
          } else {

            void this.router.navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate([this.landingPage]));
          }
        });
      }
    }, false);
  }

  private loginWithToken() {
    return this.http.post<{ auth: string }>({
      path: 'auth/login-token'
    }).pipe(
      map(response => response.data.auth),
      catchError(() => of(undefined)),
      switchMap(token => this.setToken(token)),
      take(1),
      takeUntil(this.destroy$)
    );
  }

  private waitSetAuthToken() {
    return timer(0, 100).pipe(
      take(10),
      map(() => this.token),
      truthy(),
      take(1),
      defaultIfEmpty(undefined)
    );
  }

  private waitUnsetAuthToken() {
    return timer(0, 100).pipe(
      take(10),
      map(() => this.token),
      filter(token => !token),
      take(1),
      defaultIfEmpty(undefined)
    );
  }

  setToken(value: string | null | undefined) {
    const userInfo = value ? Object.assign(this.userInfo || {}, this.jwtService.decodeToken(value)) : undefined;

    if (value) {
      if (value !== this.token) {
        localStorage.setItem('authorization', value);
      }

      return this.waitSetAuthToken().pipe(tap(() => this.userInfo = userInfo));
    } else {
      localStorage.removeItem('authorization');
      return this.waitUnsetAuthToken().pipe(tap(() => this.userInfo = userInfo));
    }
  }

  get token() {
    return localStorage.getItem('authorization') || undefined;
  }

  get userInfo() {
    return this.__userInfo$.value;
  }

  set userInfo(value) {
    this.__userInfo$.next(value || null);

    if (value?.socket) {
      this.socket.joinRoom(value.socket);
    } else {
      this.socket.leaveRoom();
    }
  }

  private isLoggingOut = false;
  logout(emit = true) {
    if (this.isLoggingOut) return;
    this.isLoggingOut = true;

    this.socket.leaveRoom();

    const unsetToken = () => {
      this.setToken(undefined).pipe(
        switchMap(() => this.waitUnsetAuthToken()),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        void this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });

        this.context.clear();
        this.cache.clear();
        this.progress.reset();

        this.isLoggingOut = false;
      });
    };

    if (emit) {
      this.http.post({ path: 'auth/logout', body: { emit } }).pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        unsetToken();
      });
    } else {
      unsetToken();
    }
  }

  get name() {
    return this.userInfo ? `${this.userInfo.firstName} ${this.userInfo.lastName}` : null;
  }

  get isProRank() {
    return this.userInfo?.recruiterId === 1;
  }

  get isAdmin() {
    return Object.keys(this.roles).length > 0;
  }

  get roles(): Record<Role, boolean> {
    return Object.keys(this.userInfo?.roles || {}).map(x => x as Role).map(key => ({ [key]: this.userInfo?.roles[key] === 1 })).reduce((a, x) => ({ ...a, ...x }), {}) as any;
  }

  get seats(): Record<Seat, boolean> {
    return Object.keys(this.userInfo?.seats || {}).map(x => x as Seat).map(key => ({ [key]: this.userInfo?.seats[key] === 1 })).reduce((a, x) => ({ ...a, ...x }), {}) as any;
  }

  get isAuthenticated() {
    return !!this.userInfo;
  }

  get landingPage() {
    if (!this.isAuthenticated) {

      return this.router.createUrlTree(['login']).toString();
    } else if (this.seats.coreApp) {
      return this.router.createUrlTree(['jobs']).toString();
    } else if (this.isAdmin) {
      return this.router.createUrlTree(['admin']).toString();
    }

    return this.router.createUrlTree(['profile', 'settings']).toString();
  }

  get isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}