import { PositionType, JobService } from 'src/app/services/api/job.service';
import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject, combineLatest, filter, of, share, startWith, switchMap, take, takeUntil, tap } from 'rxjs';
import { CandidatesService, Email, Phone, WorkPreferences, candidateHideBulk } from 'src/app/services/api/candidates.service';
import { DialogService } from 'src/app/services/dialog.service';
import { mapSuccessData, successData, tapFinished, tapLoading } from 'src/app/utils/rxjs-operators';
import { SendEmailModalComponent } from 'src/app/components/candidate-actions-module/send-email-modal/send-email-modal.component';
import { PrioritizeModalComponent } from 'src/app/components/candidate-actions-module/prioritize-modal/prioritize-modal.component';
import { ChangeStageModalComponent } from 'src/app/components/candidate-actions-module/change-stage-modal/change-stage-modal.component';
import { AddToCampaignModalComponent } from 'src/app/components/candidate-actions-module/add-to-campaign-modal/add-to-campaign-modal.component';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { PreviewResumeComponent } from 'src/app/components/candidate-popout-module/preview-resume/preview-resume.component';
import { createSuccessResult } from 'src/app/utils/api-helpers';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';
import { NotesService } from 'src/app/services/api/notes.service';
import { TasksService } from 'src/app/services/api/tasks.service';
import { JobPostingsService } from 'src/app/services/api/job-postings.service';
import { ResumesService } from 'src/app/services/api/resumes.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { EmailsService } from 'src/app/services/api/emails.service';
import { ContactInfoComponent } from '../contact-info/contact-info.component';
import { City, WorkStatus, XrefService } from 'src/app/services/api/xref.service';
import { DataTable } from 'src/app/services/api.service';
import { RestrictedListService } from 'src/app/services/api/restricted-list.service';
import { checkForRestrictedCandidates } from 'src/app/utils/candidates-helpers';
import { WatchListService } from 'src/app/services/api/watch-list.service';
import { CandidateFollowStatus, CandidateRestrictionStatus } from 'src/app/enum';
import { capitalizeWords } from 'src/app/utils/app.helpers';

@Component({
  selector: 'app-candidate-modal',
  templateUrl: './candidate-modal.component.html',
  styleUrls: ['./candidate-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CandidateModalComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  loading = false;
  TabSelected$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  currentCandidate$ = new BehaviorSubject<any | undefined>(undefined);
  requestCandidateHeader$ = new BehaviorSubject<any | undefined>(undefined);
  emailId$ = new BehaviorSubject<number | undefined>(undefined);

  versions: any[] = [];
  allowVersions = false;
  trigger = '';
  isCandidateUpdating = false;

  CandidateRestrictionStatus = CandidateRestrictionStatus;
  CandidateFollowStatus = CandidateFollowStatus;

  overview$ = this.currentCandidate$.pipe(
    switchMap(candidate => this.candidatesService.getOverview(candidate.profileRef)),
    takeUntil(this.destroy$)
  );

  analysisDetail$ = this.currentCandidate$.pipe(
    filter(() => this.data.jobId),
    switchMap(candidate => this.candidatesService.getAnalysisDetail(candidate.profileRef, this.data.jobId)),
    takeUntil(this.destroy$)
  );

  jobs$ = this.currentCandidate$.pipe(
    switchMap(candidate => this.candidatesService.getJobsMatched(candidate.profileRef, this.data.jobId)),
    takeUntil(this.destroy$)
  );

  resume$ = combineLatest([this.currentCandidate$, this.resumesService.refreshProfileRef$.asObservable()]).pipe(
    switchMap(([candidate]) => this.candidatesService.getResume(candidate.profileRef)),
    mapSuccessData(data => ({
      ...data,
      versions: data.versions.map(x => ({ ...x, url: `/api/v2/resume/download?jwt=${x.downloadToken}` }))
    })),
    successData(),
    takeUntil(this.destroy$)
  );

  activity$ = combineLatest([this.currentCandidate$, this.noteService.refreshNotes$.asObservable(), this.taskService.refreshTasks$.asObservable()]).pipe(
    switchMap(([candidate]) => this.candidatesService.getActivity(candidate.profileRef, this.data.jobId)),
    takeUntil(this.destroy$)
  );

  candidate$ = combineLatest([
    this.currentCandidate$.pipe(
      tap(value => {
        if (value) {
          this.trigger = 'header';
        }
      }),
      takeUntil(this.destroy$)
    ),
    this.requestCandidateHeader$.pipe(
      tap(value => {
        if (value) {
          this.trigger = 'requestInfo';
        }
      }),
      takeUntil(this.destroy$)
    )
  ]).pipe(
    switchMap(([candidate, request]) => {
      if (this.trigger === 'requestInfo')
        return this.candidatesService.requestContactInfo(request.profileRef, this.data.jobId).pipe(
          tapLoading(() => (this.loading = true)),
          tapFinished(() => (this.loading = false)),
          takeUntil(this.destroy$)
        );
      else return this.candidatesService.getHeaderById(candidate.profileRef, this.data.jobId).pipe(takeUntil(this.destroy$));
    }),
    successData(),
    tap(candidate =>
      this.candidateForm.patchValue({
        ...candidate,
        availableDate: candidate.availableDate ? new Date(Number(candidate.availableDate)) : null,
        positionTypeId: candidate.positionTypes.map(x => x.id)
      })
    ),
    tap(() => (this.isCandidateUpdating = false)),
    takeUntil(this.destroy$)
  );

  emailsThreads$ = this.currentCandidate$.pipe(
    switchMap((candidate: any) => this.emailsService.candidateEmailThreads(candidate.profileRef, this.data.jobId)),
    successData(),
    takeUntil(this.destroy$)
  );

  email$ = this.emailId$.pipe(
    switchMap((emailId: any) => this.emailsService.emailDetails(emailId)),
    successData(),
    takeUntil(this.destroy$)
  );

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  currentIndex = 0;

  currentCandidate: any;
  candidateActivities: any = [];
  @ViewChild('preview', { static: false }) previewModal?: TemplateRef<any>;
  addToSelection = undefined;

  addToSelection$: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);

  searchLocation = (searchValue?: string) =>
    this.jobPostings.getJobsSearch(searchValue).pipe(
      mapSuccessData(data => data.dataTable.map(x => ({ value: x.jobId, text: x.jobTitle }))),
      takeUntil(this.destroy$)
    );
  showHideEditDetails: boolean[] = [false, false, false, false, false];

  searchCitystate = (searchValue?: string) =>
    this.xref
      .get<DataTable<City>>({
        path: 'cities',
        params: searchValue || '' ? { searchValue } : { context: 'jobs' }
      })
      .pipe(
        mapSuccessData(data => data.dataTable.map(x => ({ value: x.geoId, text: x.cityState }))),
        takeUntil(this.destroy$)
      );

  WorkStatus$ = this.xref
    .get<WorkStatus[]>({
      path: 'work-statuses'
    })
    .pipe(
      successData(),

      takeUntil(this.destroy$)
    );

  positionTypes$ = this.xref
    .get<PositionType[]>({
      path: 'position-types'
    })
    .pipe(successData(), takeUntil(this.destroy$));

  payRateTypes$ = this.xref
    .get<any[]>({
      path: 'pay-rate-types'
    })
    .pipe(successData(), takeUntil(this.destroy$));
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CandidateModalComponent>,
    public candidatesService: CandidatesService,
    private dialog: DialogService,
    private dialogMat: MatDialog,
    private snackBar: MatSnackBar,
    private noteService: NotesService,
    private taskService: TasksService,
    private jobPostings: JobPostingsService,
    private resumesService: ResumesService,
    private refreshSvc: RefreshService,
    private emailsService: EmailsService,
    private JobService: JobService,
    private xref: XrefService,
    private restrictedListService: RestrictedListService,
    private watchListService: WatchListService
  ) {
    this.currentCandidate$.next(this.data.candidate);
    this.currentIndex = this.data.index;
  }

  ngOnInit() {
    this.currentCandidate$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.currentCandidate = value;
    });

    this.resume$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.versions = value.versions;
      this.allowVersions = value.allowVersions;
    });

    this.activity$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      const group = value.reduce((acc: any, curr) => {
        const key = curr.dateGroup;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
      }, {});

      this.candidateActivities = Object.keys(group).map(key => ({
        date: key,
        activities: group[key]
      }));
    });
    this.addToSelection$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value === 0) {
        this.addToCampaign();
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  uploadResumes(profileRef?: string) {
    this.dialog
      .uploadResumes({
        dialog: { title: 'Upload Resume' },
        item: { profileRef }
      })
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe();
  }

  unmask(profileRef: string) {
    return this.candidatesService
      .unmask(profileRef, this.data.jobId)
      .pipe(successData(), takeUntil(this.destroy$))
      .subscribe(() => {
        // this is wrong "this.currentCandidate$.next(this.data.candidate);"
        // this.currentCandidate$.next(this.data.candidate); // this.data.candidate will return the candidate that passed from underlying page , which may changed when we navigate throw arrows

        // instead do this just to refresh header
        this.currentCandidate$.next(this.currentCandidate$.value);
      });
  }

  addToCampaign() {
    checkForRestrictedCandidates([this.currentCandidate], this.snackBar);

    this.dialogMat
      .open(AddToCampaignModalComponent, {
        minWidth: '904px',
        maxWidth: '904px',
        data: {
          total: this.data.total,
          selectedCandidates: [this.currentCandidate.profileRef],
          jobId: Number(this.data.jobId),
          context: this.data.viewType === 1 ? 2000 : this.data.viewType === 2 ? 4000 : 3000
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.addToSelection$.next(undefined);
        this.addToSelection = undefined;
      });
  }

  sendEmail(templateTypeId: number) {
    if (templateTypeId === 21000 && this.data.isStpCommEnabled !== 0) {
      this.snackBar.open('An email rejection notice will be sent in the next hour.', 'Close', { duration: 5 * 1000, panelClass: 'info' });
      this.refreshSvc.setRefreshStagesObs(true);
      return;
    }
    this.openSendEmailDialog(templateTypeId);
  }

  openSendEmailDialog(templateTypeId: number) {
    checkForRestrictedCandidates([this.currentCandidate], this.snackBar);

    this.dialogMat
      .open(SendEmailModalComponent, {
        minWidth: '700px',
        data: {
          selectedCandidates: [this.currentCandidate],
          jobId: this.data.jobId,
          specificTemplate: templateTypeId
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data) {
          if (data.reject) {
            this.rejectApplicant(this.currentCandidate);
          } else this.refreshSvc.setRefreshStagesObs(true);
        }
      });
  }

  prioritize() {
    this.dialogMat.open(PrioritizeModalComponent, {
      minWidth: '700px',
      data: {
        selectedCandidates: [{ profileRef: this.currentCandidate.profileRef }],
        jobId: Number(this.data.jobId)
      }
    });
  }

  stage() {
    this.dialogMat.open(ChangeStageModalComponent, {
      minWidth: '700px',
      data: {
        selectedCandidates: [{ profileRef: this.currentCandidate.profileRef, candidateName: capitalizeWords(this.currentCandidate.displayName), isRestricted: this.currentCandidate.isRestricted }],
        jobId: Number(this.data.jobId),
        context: this.data.viewType === 1 ? 2000 : this.data.viewType === 2 ? 4000 : 3000,
        panelClass: 'stage-dialog'
      }
    });
  }

  previewResume() {
    if (this.versions.length) {
      this.dialogMat.open(PreviewResumeComponent, {
        minWidth: '700px',
        data: {
          versions: this.versions,
          jobId: Number(this.data.jobId),
          allowVersions: true
        }
      });
    } else {
      this.snackBar.open('No resumes available', 'Close', { duration: 5 * 1000, panelClass: 'warning' });
    }
  }

  hide() {
    const candidatesList = [{ profileRef: this.currentCandidate.profileRef }];
    const body: candidateHideBulk = {
      candidates: candidatesList,
      isActive: 0,
      jobId: Number(this.data.jobId)
    };

    of(null)
      .pipe(
        switchMap(() => this.candidatesService.hideCandidateBulk(body)),
        switchMap(result => {
          if (result.finished) {
            const message = result.success ? this.currentCandidate.displayName + ' has been hidden' : 'An error occured, please try again later';
            this.refreshSvc.setRefreshObs(true);
            this.snackBar.open(message, 'Close', { duration: 5 * 1000 });
          }

          return of(result);
        }),
        share(),
        startWith(createSuccessResult(undefined)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  handleRestriction() {
    if (this.isCandidateUpdating) {
      return;
    }

    this.isCandidateUpdating = true;

    const newRestrictionStatus = this.currentCandidate$.value.isRestricted ? CandidateRestrictionStatus.NON_RESTRICTED : CandidateRestrictionStatus.RESTRICTED;

    const payload = {
      profileRef: this.currentCandidate$.value.profileRef,
      isActive: newRestrictionStatus
    };

    this.restrictedListService
      .save([payload])
      .pipe(
        switchMap(result => {
          if (result.finished) {
            if (result.success) {
              this.currentCandidate$.next({
                ...this.currentCandidate$.value,
                isRestricted: newRestrictionStatus
              });
            }

            const message = result.success
              ? `${this.currentCandidate.displayName} has been ${this.currentCandidate.isRestricted ? 'restricted' : 'un-restricted'}`
              : 'An error occurred, please try again later';
            this.refreshSvc.setRefreshObs(true);
            this.snackBar.open(message, 'Close', { duration: 5 * 1000 });
          }

          return of(result);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  handleFollow() {
    if (this.isCandidateUpdating) {
      return;
    }

    this.isCandidateUpdating = true;

    const newFollowStatus = this.currentCandidate$.value.isFollowed ? CandidateFollowStatus.NON_FOLLOWED : CandidateFollowStatus.FOLLOWED;

    const payload = {
      profileRef: this.currentCandidate$.value.profileRef,
      isActive: newFollowStatus
    };

    this.watchListService
      .save([payload])
      .pipe(
        switchMap(result => {
          if (result.finished) {
            if (result.success) {
              this.currentCandidate$.next({
                ...this.currentCandidate$.value,
                isFollowed: newFollowStatus
              });
            }

            const message = result.success
              ? `${this.currentCandidate.displayName} has been ${this.currentCandidate.isFollowed ? 'followed' : 'un-followed'}`
              : 'An error occurred, please try again later';
            this.refreshSvc.setRefreshObs(true);
            this.snackBar.open(message, 'Close', { duration: 5 * 1000 });
          }

          return of(result);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  downloadResume() {
    if (this.versions.length > 0) saveAs(this.versions[0].url, this.versions[0].fileName);
  }

  next() {
    if (this.currentIndex < this.data.tableData.length - 1) {
      this.currentIndex = this.currentIndex + 1;
      this.currentCandidate$.next(this.data.tableData[this.currentIndex]);
    } else {
      this.currentIndex = this.currentIndex + 1;

      if (this.data.viewType === 1) {
        this.jobPostings
          .getCandidates(
            {},
            {
              jobId: this.data.jobId,
              priorityId: this.data.priorityId,
              candidateIdx: this.currentIndex,
              sortById: this.data.sortById
            }
          )
          .pipe(successData(), takeUntil(this.destroy$))
          .pipe(takeUntil(this.destroy$))
          .subscribe(value => {
            this.data.tableData.push(value.dataTable[0]);
            this.currentCandidate$.next(value.dataTable[0]);
          });
      } else {
        this.jobPostings
          .getCandidatesProgress(
            {},
            {
              jobId: this.data.jobId,
              isSource: this.data.viewType === 2 ? 1 : 0,
              sourcedMethodId: this.data.sourcedMethodId,
              candidateIdx: this.currentIndex,
              sortById: this.data.sortById
            }
          )
          .pipe(successData(), takeUntil(this.destroy$))
          .pipe(takeUntil(this.destroy$))
          .subscribe(value => {
            this.data.tableData.push(value.dataTable[0]);
            this.currentCandidate$.next(value.dataTable[0]);
          });
      }
    }
  }
  previous() {
    if (this.currentIndex - 1 >= 0) {
      this.currentIndex = this.currentIndex - 1;
      this.currentCandidate$.next(this.data.tableData[this.currentIndex]);
    }
  }

  addToJob(jobId: number) {
    this.JobService.addCandidateToJob(jobId, this.currentCandidate.profileRef)
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        const message = result.success ? this.currentCandidate.displayName + ' has been added to Job' : 'An error occured, please try again later';
        this.snackBar.open(message, 'Close', { duration: 5 * 1000 });
        this.addToSelection$.next(undefined);
        this.addToSelection = undefined;
      });
  }

  openEmail(id: number) {
    if (this.previewModal) {
      this.emailId$.next(id);
      this.dialogMat.open(this.previewModal, { minWidth: '80vw' });
    }
  }

  editContactInfo(phones: Phone[], emails: Email[]) {
    this.dialogMat
      .open(ContactInfoComponent, {
        minWidth: '54vw',
        data: {
          phones: Object.assign(
            [],
            phones?.filter((x: Phone) => x.contactId)
          ),
          emails: Object.assign(
            [],
            emails?.filter((x: Email) => x.emailId)
          ),

          profileRef: this.currentCandidate.profileRef
        }
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        if (value.profileRef) this.currentCandidate$.next(value);
      });
  }

  candidateForm = this.fb.group({
    firstName: new FormControl<string>(''),
    lastName: new FormControl<string>(''),
    preferredName: new FormControl<string>(''),
    workStatusId: new FormControl<number>(0),
    minPayRate: new FormControl<any>(''),
    maxPayRate: new FormControl<any>(''),
    payTypeId: new FormControl<number>(0),
    positionTypeId: new FormControl<number[]>([]),
    availableDate: new FormControl<Date>(new Date()),
    geoId: new FormControl<string>('')
  });

  saveName() {
    this.loading = true;

    of(null)
      .pipe(
        switchMap(() =>
          this.candidatesService.updateName(
            this.currentCandidate.profileRef,
            this.candidateForm.value.firstName || '',
            this.candidateForm.value.lastName || '',
            this.candidateForm.value.preferredName || ''
          )
        ),
        switchMap(result => {
          if (result.finished) {
            this.loading = false;
            const successMessage = result.success ? 'Candidate Name has been updated.' : 'An error occurred, please try again later.';
            this.snackBar.open(successMessage, 'Close', { duration: 5 * 1000 });
            if (result.success) {
              this.refreshSvc.setRefreshObs(true);
              this.currentCandidate$.next(this.currentCandidate$.value);
            }
            this.showHideEditDetails[0] = false;
          }
          return of(result);
        }),
        share(),
        startWith(createSuccessResult(undefined)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  saveLocation() {
    this.loading = true;

    of(null)
      .pipe(
        switchMap(() => this.candidatesService.updateCandidateLocation(this.currentCandidate.profileRef, Number(this.candidateForm.value.geoId))),
        switchMap(result => {
          if (result.finished) {
            this.loading = false;
            const successMessage = result.success ? 'Candidate location has been updated.' : 'An error occurred, please try again later.';
            this.snackBar.open(successMessage, 'Close', { duration: 5 * 1000 });
            if (result.success) {
              this.refreshSvc.setRefreshObs(true);
              this.currentCandidate$.next(this.currentCandidate$.value);
            }
            this.showHideEditDetails[2] = false;
          }
          return of(result);
        }),
        share(),
        startWith(createSuccessResult(undefined)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  saveWorkStatus() {
    this.loading = true;

    of(null)
      .pipe(
        switchMap(() => this.candidatesService.updateCandidateWorkStatus(this.currentCandidate.profileRef, this.candidateForm.value.workStatusId || 0)),
        switchMap(result => {
          if (result.finished) {
            this.loading = false;
            const successMessage = result.success ? 'Candidate work status has been updated.' : 'An error occurred, please try again later.';
            this.snackBar.open(successMessage, 'Close', { duration: 5 * 1000 });
            if (result.success) {
              this.currentCandidate$.next(this.currentCandidate$.value);
            }
            this.showHideEditDetails[3] = false;
          }
          return of(result);
        }),
        share(),
        startWith(createSuccessResult(undefined)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  saveWorkPreferences() {
    this.loading = true;
    const body: WorkPreferences = {
      availableDate: this.candidateForm.value.availableDate?.getTime() + '',
      maxPayRate: this.candidateForm.value.maxPayRate,
      minPayRate: this.candidateForm.value.minPayRate,
      payTypeId: this.candidateForm.value.payTypeId || undefined,
      positionTypeId: this.candidateForm.value.positionTypeId || []
    };

    of(null)
      .pipe(
        switchMap(() => this.candidatesService.updateCandidateWorkPreferences(this.currentCandidate.profileRef, body)),
        switchMap(result => {
          if (result.finished) {
            this.loading = false;
            const successMessage = result.success ? 'Candidate work preferences has been updated.' : 'An error occurred, please try again later.';
            this.snackBar.open(successMessage, 'Close', { duration: 5 * 1000 });
            if (result.success) {
              this.currentCandidate$.next(this.currentCandidate$.value);
            }
            this.showHideEditDetails[4] = false;
          }
          return of(result);
        }),
        share(),
        startWith(createSuccessResult(undefined)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  rejectApplicant(candidate: any) {
    const body: any = {
      jobId: this.data.jobId,
      sourcedMethodId: candidate.sourcedMethodId,
      stageId: 9900,
      candidates: [{ profileRef: candidate.profileRef }]
    };

    of(null)
      .pipe(
        switchMap(() => this.candidatesService.changeStage(body)),
        switchMap(result => {
          const msg = result.finished ? (result.success ? `${candidate.displayName} was rejected.` : 'An error occurred, please try again later') : undefined;

          if (msg) {
            this.snackBar.open(msg, 'Close', { duration: 5000, panelClass: 'info' });
            this.refreshSvc.setRefreshStagesObs(true);
          }

          return of(result);
        }),
        share(),
        startWith(createSuccessResult(undefined)),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
